@import 'styles/config';

.footer {
  margin-top: 200px;
  @include section(80, 32, 40, 32);

  position: sticky;
  top: 100vh;

  border-radius: 8px;

  background: $color-grey-1;

  color: $color-secondary;

  &__grid {
    row-gap: var(--gutter);

    align-items: space-between;
  }
  &__item__first {
    grid-column-end: span -1 !important;

    @media (min-width: $min-desktop) {
      grid-column-end: span 5 !important;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
        }
      }
    }
  }
}
